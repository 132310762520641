import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Tooltip,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    createTheme,
    ThemeProvider,
    CssBaseline
} from '@mui/material';
import { PhotoCamera, Edit, Delete, Add } from '@mui/icons-material';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import Sidebar from '../pages/Sidebar';
import Navbar from '../Navbar';

const theme = createTheme({
    palette: {
        primary: {
            main: '#673ab7', // Dark purple
        },
        background: {
            default: '#2e2e2e', // Dark background
        },
    },
    typography: {
        h5: {
            fontWeight: 300,
            color: '#ffffff',
        },
    },
});

const Background = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [newBackground, setNewBackground] = useState({ namabackground: '', image: '' });
    const [updateBackground, setUpdateBackground] = useState({ id: '', namabackground: '', image: '' });
    const [selectedNewImage, setSelectedNewImage] = useState(null);
    const [selectedUpdateImage, setSelectedUpdateImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://visoncard.yoshidindonesia.com/background');
                setData(response.data);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const [open, setOpen] = useState(true);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleOpenImageDialog = (image) => {
        setSelectedImage(image);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
    };

    const handleOpenCreateDialog = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
        setNewBackground({ namabackground: '', image: '' });
        setSelectedNewImage(null);
    };

    const handleCreateChange = (e) => {
        const { name, value } = e.target;
        setNewBackground((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCreateFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'image') {
            setSelectedNewImage(files[0]);
        }
    };

    const handleCreateSubmit = async () => {
        const formData = new FormData();
        formData.append('namabackground', newBackground.namabackground);
        if (selectedNewImage) formData.append('image', selectedNewImage);

        try {
            await axios.post('https://visoncard.yoshidindonesia.com/background', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            Toastify({
                text: "Content created successfully!",
                duration: 3000,
                close: true,
                gravity: "top",
                position: "right",
                backgroundColor: "#4caf50",
            }).showToast();
            setOpenCreateDialog(false);
            const response = await axios.get('https://visoncard.yoshidindonesia.com/background');
            setData(response.data);
        } catch (err) {
            Toastify({
                text: "Failed to create content!",
                duration: 3000,
                close: true,
                gravity: "top",
                position: "right",
                backgroundColor: "#f44336",
            }).showToast();
        }
    };

    const handleOpenUpdateDialog = (item) => {
        setUpdateBackground({ id: item.id, namabackground: item.namabackground, image: item.image });
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
        setUpdateBackground({ id: '', namabackground: '', image: '' });
        setSelectedUpdateImage(null);
    };

    const handleUpdateChange = (e) => {
        const { name, value } = e.target;
        setUpdateBackground((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUpdateFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'image') {
            setSelectedUpdateImage(files[0]);
        }
    };

    const handleUpdateSubmit = async () => {
        const formData = new FormData();
        formData.append('namabackground', updateBackground.namabackground);
        if (selectedUpdateImage) formData.append('image', selectedUpdateImage);

        try {
            await axios.put(`https://visoncard.yoshidindonesia.com/background/${updateBackground.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            Toastify({
                text: "Content updated successfully!",
                duration: 3000,
                close: true,
                gravity: "top",
                position: "right",
                backgroundColor: "#4caf50",
            }).showToast();
            setOpenUpdateDialog(false);
            const response = await axios.get('https://visoncard.yoshidindonesia.com/background');
            setData(response.data);
        } catch (err) {
            Toastify({
                text: "Failed to update content!",
                duration: 3000,
                close: true,
                gravity: "top",
                position: "right",
                backgroundColor: "#f44336",
            }).showToast();
        }
    };

    const handleDelete = async (item) => {
        try {
            await axios.delete(`https://visoncard.yoshidindonesia.com/background/${item.id}`);
            Toastify({
                text: "Content deleted successfully!",
                duration: 3000,
                close: true,
                gravity: "top",
                position: "right",
                backgroundColor: "#4caf50",
            }).showToast();
            const response = await axios.get('https://visoncard.yoshidindonesia.com/background');
            setData(response.data);
        } catch (err) {
            Toastify({
                text: "Failed to delete content!",
                duration: 3000,
                close: true,
                gravity: "top",
                position: "right",
                backgroundColor: "#f44336",
            }).showToast();
        }
    };

    if (loading) return <Typography variant="h6">Loading...</Typography>;
    if (error) return <Typography variant="h6" color="error">{error}</Typography>;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>
                <Navbar onToggle={handleDrawerToggle} />
                <Sidebar open={open} onToggle={handleDrawerToggle} />
                <Box sx={{ p: 3, flexGrow: 1 }} style={{ marginTop: '60px' }}>
                    <Typography variant="h4" component="h1" gutterBottom style={{color: 'white'}}>
                        Background
                    </Typography>
                    <Button variant="contained" style={{ backgroundColor: '#ff8c00', color: 'black', fontWeight: 'bold' }} onClick={handleOpenCreateDialog}>
                        Add New Background
                    </Button>
                    <TableContainer 
    component={Paper} 
    sx={{ 
        mt: 2, 
        maxHeight: '680px', // Fixed height for scrolling
        backgroundColor: '#3e3e3e', 
        color: 'white',
        overflowY: 'auto' // Enable vertical scrolling
    }}
>
    <Table stickyHeader>
        <TableHead>
            <TableRow>
                <TableCell sx={{ fontWeight: 'bold', background: 'linear-gradient(15deg, #ff8c00, #D2691E)', color: '#fff' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', background: 'linear-gradient(15deg, #ff8c00, #D2691E)', color: '#fff' }}>Image</TableCell>
                <TableCell sx={{ fontWeight: 'bold', background: 'linear-gradient(15deg, #ff8c00, #D2691E)', color: '#fff' }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((item) => (
                <TableRow key={item.id}>
                    <TableCell style={{color: 'white'}}>{item.namabackground}</TableCell>
                    <TableCell>
                        <img
                            src={item.imageUrl}
                            alt={item.namabackground}
                            style={{ maxWidth: '100px', cursor: 'pointer' }}
                            onClick={() => handleOpenImageDialog(item.imageUrl)}
                        />
                    </TableCell>
                    <TableCell>
                        <Tooltip title="Edit">
                            <IconButton style={{color: 'white'}} onClick={() => handleOpenUpdateDialog(item)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton style={{color: 'red'}} onClick={() => handleDelete(item)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>

                </Box>
            </Box>

            {/* Image Dialog */}
            <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
                <DialogTitle>Image</DialogTitle>
                <DialogContent>
                    <img
                        src={selectedImage}
                        alt="Background"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseImageDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Create Dialog */}
            <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
                <DialogTitle>Create New Background</DialogTitle>
                <DialogContent>
                    <TextField
                        name="namabackground"
                        label="Name"
                        fullWidth
                        value={newBackground.namabackground}
                        onChange={handleCreateChange}
                        margin="normal"
                    />
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="image-upload"
                        type="file"
                        name="image"
                        onChange={handleCreateFileChange}
                    />
                    <label htmlFor="image-upload">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<PhotoCamera />}
                        >
                            Upload Image
                        </Button>
                    </label>
                    {selectedNewImage && (
                        <Typography variant="body2" color="textSecondary">
                            {selectedNewImage.name}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCreateDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateSubmit} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Update Dialog */}
            <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
                <DialogTitle>Update Background</DialogTitle>
                <DialogContent>
                    <TextField
                        name="namabackground"
                        label="Name"
                        fullWidth
                        value={updateBackground.namabackground}
                        onChange={handleUpdateChange}
                        margin="normal"
                    />
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="image-upload-update"
                        type="file"
                        name="image"
                        onChange={handleUpdateFileChange}
                    />
                    <label htmlFor="image-upload-update">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<PhotoCamera />}
                        >
                            Update Image
                        </Button>
                    </label>
                    {selectedUpdateImage && (
                        <Typography variant="body2" color="textSecondary">
                            {selectedUpdateImage.name}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdateDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateSubmit} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default Background;
