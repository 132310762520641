import React, { useState, useEffect } from 'react';
import {
    CssBaseline, Container, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tooltip, MenuItem, Select, Grid,
    RadioGroup, FormControlLabel, Radio, Checkbox
} from '@mui/material';

import { PhotoCamera, WhatsApp, Edit, Delete, FileCopy, Search } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Sidebar from '../pages/Sidebar';
import Navbar from '../Navbar';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";


const theme = createTheme({
    palette: {
        primary: {
            main: '#ff8c00', // Dark orange
        },
        background: {
            default: '#2e2e2e', // Dark background
        },
    },
    typography: {
        h5: {
            fontWeight: 300,
            color: '#ffffff',
        },
    },
});



const sendLinkToWhatsApp = (username) => {
    const url = `https://v1sioncard.com/profile/${username}`;
    const message = `Please copy the link below and enter your profile link into NFC Tools to write this profile, don't forget to remove the old data in NFC tools, or a new NFC card is highly recommended : ${url}`;
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/?text=${encodedMessage}`, '_blank');
};




const copyLinkToClipboard = (username) => {
    const url = `https://v1sioncard.com/profile/${username}`;
    navigator.clipboard.writeText(url)
        .then(() => {
            Toastify({
                text: "Link copied to clipboard!",
                duration: 3000,
                gravity: "top",
                position: "right",
                backgroundColor: "#4CAF50",
            }).showToast();
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
};

const User = () => {
    const [errors, setErrors] = useState({});
    const [data, setData] = useState([]);
    const [backgrounds, setBackgrounds] = useState([]);
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

    const [newUser, setNewUser] = useState({
        fontcolor: '#000000',
        namalengkap: '', username: '', email: '', whatsapp: '', instagram: '', password: '',
        facebook: '', linkedin: '', tiktok: '', youtube: '', jabatan: '', alamat: '', image: '', backgroundId: ''
    });
    
    const [updateUser, setUpdateUser] = useState({
        fontcolor: '#000000',
        id: '', namalengkap: '', username: '', email: '', whatsapp: '', instagram: '', password: '',
        facebook: '', linkedin: '', tiktok: '', youtube: '', jabatan: '', alamat: '', image: '', backgroundId: ''
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        fetchData();
        fetchBackgrounds();
    }, []);

    useEffect(() => {
        if (selectAll) {
            setSelectedRows(data.map(user => user.username));
        } else {
            setSelectedRows([]);
        }
    }, [selectAll]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://visoncard.yoshidindonesia.com/user');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchBackgrounds = async () => {
        try {
            const response = await axios.get('https://visoncard.yoshidindonesia.com/background');
            setBackgrounds(response.data);
        } catch (error) {
            console.error('Error fetching backgrounds:', error);
        }
    };

    const [open, setOpen] = useState(true);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };



    
    const handleOpenImageDialog = (image) => {
        setSelectedImage(image);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
    };

    const handleOpenCreateDialog = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setNewUser({
            namalengkap: "",
            username: "",
            email: "",
            whatsapp: "",
            facebook: "",
            instagram: "",
            linkedin: "",
            youtube: "",
            tiktok: "",
            jabatan: "",
            alamat: "",
            password: "",
            role: "",
            fontcolor: "",
            image: null,
            imageUrl: "",
            backgroundId: "",
        });
        setErrors({});
        setOpenCreateDialog(false);
    };

    const handleOpenUpdateDialog = (user) => {
        setUpdateUser(user);
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    const handleCreateChange = (event) => {
        setNewUser({ ...newUser, [event.target.name]: event.target.value });
    };

    const handleCreateFileChange = (event) => {
        setNewUser({ ...newUser, image: event.target.files[0] });
    };

    const handleCreateSubmit = async () => {
        // Validate form data
        let formErrors = {};
        if (!newUser.namalengkap) formErrors.namalengkap = "Nama Lengkap tidak boleh kosong!";
        if (!newUser.username) formErrors.username = "Username tidak boleh kosong!";
        if (!newUser.email) formErrors.email = "Email tidak boleh kosong!";
        if (!newUser.password) formErrors.password = "Password tidak boleh kosong!";
        if (!newUser.jabatan) formErrors.jabatan = "Jabatan tidak boleh kosong!";
        if (!newUser.alamat) formErrors.alamat = "Alamat tidak boleh kosong!";
        if (!newUser.image) formErrors.image = "Image tidak boleh kosong!";
        setErrors(formErrors);
    
        if (Object.keys(formErrors).length > 0) return;
    
        const formData = new FormData();
        Object.keys(newUser).forEach(key => formData.append(key, newUser[key]));
    
        try {
            const response = await axios.post('https://visoncard.yoshidindonesia.com/user/register-user', formData);
            console.log('Data berhasil ditambahkan:', response.data);
    
            // Display success toast with `namalengkap`
            Toastify({
                text: `Data berhasil ditambahkan untuk ${newUser.namalengkap}`,
                duration: 3000,
                gravity: "top",
                position: "right",
                backgroundColor: "#4CAF50", // Green for success
            }).showToast();
    
            // Reset form data and errors
            setNewUser({
                namalengkap: "",
                username: "",
                email: "",
                whatsapp: "",
                facebook: "",
                instagram: "",
                linkedin: "",
                youtube: "",
                tiktok: "",
                jabatan: "",
                alamat: "",
                password: "",
                role: "",
                fontcolor: "",
                image: null,
                imageUrl: "",
                backgroundId: "",
            });
            setErrors({});
    
            fetchData(); // Refresh data
            handleCloseCreateDialog();
        } catch (error) {
            console.error('Error saat menambahkan data:', error.response ? error.response.data : error.message);
    
            // Handle error toast
            Toastify({
                text: "Gagal menambahkan data. Silakan coba lagi.",
                duration: 3000,
                gravity: "top",
                position: "right",
                backgroundColor: "#f44336", // Red for error
            }).showToast();
        }
    };

    const handleUpdateChange = (event) => {
        setUpdateUser({ ...updateUser, [event.target.name]: event.target.value });
    };

    const handleUpdateFileChange = (event) => {
        setUpdateUser({ ...updateUser, image: event.target.files[0] });
    };

    const handleUpdateSubmit = async () => {
        // Validate form data
        let formErrors = {};
        if (!updateUser.namalengkap) formErrors.namalengkap = "Nama Lengkap tidak boleh kosong!";
        if (!updateUser.username) formErrors.username = "Username tidak boleh kosong!";
        if (!updateUser.email) formErrors.email = "Email tidak boleh kosong!";
        if (!updateUser.password) formErrors.password = "Password tidak boleh kosong!";
        if (!updateUser.jabatan) formErrors.jabatan = "Jabatan tidak boleh kosong!";
        if (!updateUser.alamat) formErrors.alamat = "Alamat tidak boleh kosong!";
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) return;

        const formData = new FormData();
        Object.keys(updateUser).forEach(key => formData.append(key, updateUser[key]));
        try {
            await axios.put(`https://visoncard.yoshidindonesia.com/user/user/${updateUser.id}`, formData);
             // Display success toast with `namalengkap`
        Toastify({
            text: `Data berhasil diperbarui untuk ${updateUser.namalengkap}`,
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#4CAF50", // Green for success
        }).showToast();
            
            fetchData();
            handleCloseUpdateDialog();
        } catch (error) {
            console.error('Error updating user:', error);
            // Display error toast
        Toastify({
            text: "Gagal memperbarui data. Silakan coba lagi.",
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#f44336", // Red for error
        }).showToast();
        }
    };

    const handleDelete = async (user) => {
        try {
            await axios.delete(`https://visoncard.yoshidindonesia.com/user/user/${user.id}`);
            
        // Display success toast with `namalengkap`
        Toastify({
            text: `Data ${user.namalengkap} berhasil dihapus.`,
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#4CAF50", // Green for success
        }).showToast();
            fetchData();
        } catch (error) {
             // Display error toast
        Toastify({
            text: "Gagal menghapus data. Silakan coba lagi.",
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#f44336", // Red for error
        }).showToast();
            console.error('Error deleting user:', error);
        }
    };


    const previewFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setNewUser(prevState => ({
                ...prevState,
                image: file,
                imageUrl: imageUrl
            }));
        }
    };



    
    




    const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
    };

    const handleRowSelectChange = (username) => {
        setSelectedRows(prevSelectedRows =>
            prevSelectedRows.includes(username)
                ? prevSelectedRows.filter(item => item !== username)
                : [...prevSelectedRows, username]
        );
    };

    const handleCopyLinks = () => {
        if (selectedRows.length === 0) return;
    
        // Membuat string yang berisi semua link yang dipisahkan dengan newline
        const links = selectedRows.map(username => `https://v1sioncard.com/profile/${username}`).join('\n');
    
        navigator.clipboard.writeText(links)
            .then(() => {
                Toastify({
                    text: `${selectedRows.length} link(s) copied to clipboard!`,
                    duration: 3000,
                    gravity: "top",
                    position: "right",
                    backgroundColor: "#4CAF50",
                }).showToast();
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                Toastify({
                    text: "Failed to copy links.",
                    duration: 3000,
                    gravity: "top",
                    position: "right",
                    backgroundColor: "#F44336",
                }).showToast();
            });
    };

    const filteredData = data.filter((item) =>
        item.namalengkap.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
            <Box style={{ marginTop: '60px', maxWidth: '100%', width: '95%', marginLeft: '200px' }}>
                <Navbar onToggle={handleDrawerToggle} />
                <Sidebar open={open} onToggle={handleDrawerToggle} />
                <Typography variant="h4" color="white" gutterBottom>User Management</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" onClick={handleOpenCreateDialog} style={{ fontWeight: 'bold', width: '100%' }}>
                            Create User
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" onClick={handleCopyLinks} style={{ fontWeight: 'bold', width: '100%', backgroundColor: 'green', color: 'white' }}>
                            Copy Link <FileCopy style={{ marginLeft: '12px', color: 'white' }} />
                        </Button>
                    </Grid>
                </Grid>

                <Box mt={2} sx={{ borderColor: 'white', backgroundColor: 'white', borderRadius: '12px', border: '0' }}>
                    <TextField
                        label="Search By Username"
                        variant="outlined"
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            endAdornment: <Search />
                        }}
                    />
                </Box>

                <TableContainer className="table-container" component={Paper} sx={{ mt: 2 }} style={{ maxHeight: '680px', overflowX: 'auto', overflowY: 'auto', backgroundColor: '#3e3e3e', color: 'white' }}>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, green)' }}>
                    Select All
                    <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        inputProps={{ 'aria-label': 'select all' }}
                    />
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Actions</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Image</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Background</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Nama Lengkap</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Username</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>WhatsApp</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Instagram</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Facebook</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>LinkedIn</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>TikTok</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>YouTube</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Jabatan</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', background: 'linear-gradient(15deg, #ff8c00, #D2691E)' }}>Alamat</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {filteredData.map((user) => (
                <TableRow key={user.id}>
                    <TableCell>
                        <Checkbox
                            checked={selectedRows.includes(user.username)}
                            onChange={() => handleRowSelectChange(user.username)}
                        />
                    </TableCell>
                    <TableCell sx={{backgroundColor: 'white'}}>
                        <Tooltip title="Update">
                            <IconButton onClick={() => handleOpenUpdateDialog(user)}>
                                <Edit style={{ color: '#ff8c00' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(user)}>
                                <Delete style={{ color: 'red' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Copy Link">
                            <IconButton onClick={() => copyLinkToClipboard(user.username)}>
                                <FileCopy style={{ color: 'green' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Send Link via WhatsApp">
                            <IconButton onClick={() => sendLinkToWhatsApp(user.username)}>
                                <WhatsApp style={{ color: 'green' }} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <img
                            src={user.image}
                            alt="Profile"
                            width="50"
                            height="50"
                            onClick={() => handleOpenImageDialog(user.image)}
                            style={{ cursor: 'pointer' }}
                        />
                    </TableCell>
                    <TableCell>
                        <img
                            src={user.backgroundImage}
                            alt="Profile"
                            width="50"
                            height="50"
                            onClick={() => handleOpenImageDialog(user.backgroundImage)}
                            style={{ cursor: 'pointer' }}
                        />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.namalengkap}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.username}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.email}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.whatsapp}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.instagram}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.facebook}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.linkedin}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.tiktok}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.youtube}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.jabatan}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>{user.alamat}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
                </Box>

                <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
                    <DialogTitle>Image Preview</DialogTitle>
                    <DialogContent>
                        <img src={selectedImage} alt="Profile" style={{ width: '100%' }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseImageDialog}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
    <DialogTitle>Create User</DialogTitle>
    <DialogContent>
        {/* Image Upload Section */}
        <Box mb={2}>
            <Button
                variant="contained"
                component="label"
                style={{ marginTop: '30px', marginBottom: '30px' }}
            >
                Upload Image
                <input
                    type="file"
                    accept="image/*"
                    style={{ marginTop: 16 }}
                    hidden
                    onChange={(e) => {
                        handleCreateFileChange(e);
                        previewFile(e);
                    }}
                />
            </Button>
            {errors.image && <Typography color="error">{errors.image}</Typography>}
            {newUser.image && (
                <Box mt={2}>
                    <Typography variant="subtitle1">Image Preview:</Typography>
                    <img
                        src={newUser.imageUrl || URL.createObjectURL(newUser.image)}
                        alt="Preview"
                        style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }}
                    />
                </Box>
            )}
        </Box>

        {/* Font Color Selector */}
        <TextField
            label="Font Color"
            type="color"
            name="fontcolor"
            value={newUser.fontcolor}
            onChange={handleCreateChange}
            fullWidth
        />
        <Typography variant="body1" style={{ color: newUser.fontcolor }}>
            Selected Color: {newUser.fontcolor}
        </Typography>

        {/* User Information Fields */}
        <TextField
            autoFocus
            margin="dense"
            name="namalengkap"
            label="Nama Lengkap"
            type="text"
            fullWidth
            variant="outlined"
            value={newUser.namalengkap}
            onChange={handleCreateChange}
            required
            error={!!errors.namalengkap}
            helperText={errors.namalengkap}
        />
        <TextField
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            variant="outlined"
            value={newUser.username}
            onChange={handleCreateChange}
            required
            error={!!errors.username}
            helperText={errors.username}
        />
        <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={newUser.email}
            onChange={handleCreateChange}
            required
            error={!!errors.email}
            helperText={errors.email}
        />
        <TextField
            margin="dense"
            name="whatsapp"
            label="WhatsApp"
            fullWidth
            value={newUser.whatsapp}
            onChange={handleCreateChange}
        />
        <small style={{ color: 'green' }}><i>Optional / boleh kosong</i></small>
        <TextField
            margin="dense"
            name="instagram"
            label="Instagram"
            fullWidth
            value={newUser.instagram}
            onChange={handleCreateChange}
        />
        <small style={{ color: 'green' }}><i>Optional / boleh kosong</i></small>
        <TextField
            margin="dense"
            name="facebook"
            label="Facebook"
            fullWidth
            value={newUser.facebook}
            onChange={handleCreateChange}
        />
        <small style={{ color: 'green' }}><i>Optional / boleh kosong</i></small>
        <TextField
            margin="dense"
            name="linkedin"
            label="LinkedIn"
            fullWidth
            value={newUser.linkedin}
            onChange={handleCreateChange}
        />
        <small style={{ color: 'green' }}><i>Optional / boleh kosong</i></small>
        <TextField
            margin="dense"
            name="tiktok"
            label="TikTok"
            fullWidth
            value={newUser.tiktok}
            onChange={handleCreateChange}
        />
        <small style={{ color: 'green' }}><i>Optional / boleh kosong</i></small>
        <TextField
            margin="dense"
            name="youtube"
            label="YouTube"
            fullWidth
            value={newUser.youtube}
            onChange={handleCreateChange}
        />
        <TextField
            margin="dense"
            name="jabatan"
            label="Jabatan"
            type="text"
            fullWidth
            variant="outlined"
            value={newUser.jabatan}
            onChange={handleCreateChange}
            required
            error={!!errors.jabatan}
            helperText={errors.jabatan}
        />
        <TextField
            margin="dense"
            name="alamat"
            label="Alamat"
            type="text"
            fullWidth
            variant="outlined"
            value={newUser.alamat}
            onChange={handleCreateChange}
            required
            error={!!errors.alamat}
            helperText={errors.alamat}
        />
        <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={newUser.password}
            onChange={handleCreateChange}
            required
            error={!!errors.password}
            helperText={errors.password}
        />

        {/* Background Image Selection */}
        <Box mb={2}>
            <DialogTitle>Pilih Background Image</DialogTitle>
            <RadioGroup
                name="backgroundId"
                value={newUser.backgroundId}
                onChange={handleCreateChange}
                row
            >
                {backgrounds.map(bg => (
                    <FormControlLabel
                        key={bg.id}
                        value={bg.id}
                        control={<Radio />}
                        label={<img src={bg.imageUrl} alt="Background" style={{ width: '100px' }} />}
                    />
                ))}
            </RadioGroup>
        </Box>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseCreateDialog}>Cancel</Button>
        <Button onClick={handleCreateSubmit} color="primary" variant="contained">
            Create
        </Button>
    </DialogActions>
</Dialog>


                <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
                    <DialogTitle>Update User</DialogTitle>
                    <DialogContent>
                    <Box mb={2}>
                        <Button variant="contained" component="label" style={{marginTop: '30px', marginBottom: '30px'}}>
                                Upload Image
                                <input type="file" accept="image/*" hidden onChange={handleCreateFileChange} />
                            </Button>
                            </Box>
                            <TextField
            margin="dense"
            name="fontcolor"
            label="Font Color"
            type="color"
            fullWidth
            value={updateUser.fontcolor}
            onChange={handleUpdateChange}
        />
                        <TextField autoFocus margin="dense" name="namalengkap" label="Nama Lengkap" fullWidth value={updateUser.namalengkap} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="username" label="Username" fullWidth value={updateUser.username} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="email" label="Email" fullWidth value={updateUser.email} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="whatsapp" label="WhatsApp" fullWidth value={updateUser.whatsapp} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="instagram" label="Instagram" fullWidth value={updateUser.instagram} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="facebook" label="Facebook" fullWidth value={updateUser.facebook} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="linkedin" label="LinkedIn" fullWidth value={updateUser.linkedin} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="tiktok" label="TikTok" fullWidth value={updateUser.tiktok} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="youtube" label="YouTube" fullWidth value={updateUser.youtube} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="jabatan" label="Jabatan" fullWidth value={updateUser.jabatan} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="alamat" label="Alamat" fullWidth value={updateUser.alamat} onChange={handleUpdateChange} />
                        <TextField margin="dense" name="password" label="Password" type="password" fullWidth value={updateUser.password} onChange={handleUpdateChange} />
                        <Box mb={2}>
                        <DialogTitle>Old Background Image</DialogTitle>
            <RadioGroup name="backgroundId" value={updateUser.backgroundId} onChange={handleUpdateChange} row>
                {backgrounds.map(bg => (
                    <FormControlLabel key={bg.id} value={bg.id} control={<Radio />} label={<img src={bg.imageUrl} alt="Background" style={{ width: '100px' }} />} />
                ))}
            </RadioGroup>
        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdateDialog}>Cancel</Button>
                        <Button onClick={handleUpdateSubmit}>Update</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </ThemeProvider>
    );
};

export default User;
