import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff8c00', // Orange color
        },
        background: {
            default: '#2e2e2e', // Dark background
        },
    },
    typography: {
        h4: {
            fontWeight: 600,
            color: '#ffffff',
        },
    },
});

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const handleLogin = async () => {
        try {
            const token = await login(email, password);
            toast.success("Login successful!");
            navigate('/dashboard');
        } catch (error) {
            toast.error("Login failed. Please try again.");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxHeight:'100%',
                    marginTop: '200px',
                    height: '90%',
                    backgroundImage: 'url(https://www.transparenttextures.com/patterns/dark-matter.png)',
                    backgroundColor: '#2e2e2e',
                    backgroundBlendMode: 'overlay',
                    
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        padding: 4,
                        width: '370px',
                        borderRadius: 2,
                        boxShadow: 3,
                    }}
                >
                    <div>
                        <img src="/logo.png" alt="Logo" style={{ borderRadius: '5px', width: '80px', height: 'auto' }} />
                    </div>
                    <Typography style={{ color: '#8B008B', fontWeight: 'bold' }} variant="h4" gutterBottom>
                        Vision Card
                    </Typography>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#8B008B',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#8B008B',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#8B008B',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: 'primary',
                            },
                            '& .MuiInputBase-input': {
                                color: 'primary',
                            },
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#ff00ff',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#8B008B',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#ff00ff',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: 'primary',
                            },
                            '& .MuiInputBase-input': {
                                color: 'primary',
                            },
                        }}
                    />
                    <Button
                        onClick={handleLogin}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Login
                    </Button>
                </Box>


               
            </Box>
            <ToastContainer />

           
        </ThemeProvider>
    );
};

export default Login;