import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = ({ onToggle }) => {
    return (
        <AppBar
            position="fixed"
            style={{borderBottomRightRadius: '50px',}}
            sx={{
                
                background: 'linear-gradient(to right, #D2691E, #1B1B1B)', // Gradient background
                color: '#ffffff', // White text color
                boxShadow: 'none', // Optional: remove shadow for a cleaner look
            }}
        >
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onToggle}
                    sx={{ marginRight: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Vision Card V2.0.0.0
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
