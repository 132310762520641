import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Box, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link, useNavigate } from 'react-router-dom';

const drawerWidth = 250;

const Sidebar = ({ open, onToggle }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        navigate('/');
    };

    return (
        <Drawer
      
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    height: 'calc(100% - 64px)',
                    top: '64px',
                    background: 'linear-gradient(to bottom, #D2691E, #1B1B1B)',
                    color: '#ffffff',
                    borderBottomRightRadius: '50px', 
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }} >
                <IconButton onClick={onToggle} sx={{ color: '#ffffff' }}>
                    <ChevronLeftIcon />
                </IconButton>
            </Box>
            <List>
                <ListItem button component={Link} to="/dashboard" sx={{ color: '#ffffff' }}>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/users" sx={{ color: '#ffffff' }}>
                    <ListItemText primary="Users" />
                </ListItem>
                <ListItem button component={Link} to="/background" sx={{ color: '#ffffff' }}>
                    <ListItemText primary="Background" />
                </ListItem>
                {/* <ListItem button component={Link} to="/about" sx={{ color: '#ffffff' }}>
                    <ListItemText primary="About" />
                </ListItem> */}
                <ListItem>
                <Button onClick={handleLogout} variant="contained" style={{backgroundColor: '#ff8c00', color: 'white'}} sx={{ mt: 2 }}>
                    Logout
                </Button>
                </ListItem>
               
            </List>
        </Drawer>
    );
};

export default Sidebar;
