import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import User from './components/pages/User';
import Background from './components/pages/Background';
import ProfileDetail from './components/ProfileDetail';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Box sx={{ minHeight: '100vh', p: 3 }}>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
                        <Route path="/users" element={<ProtectedRoute element={<User />} />} />
                        <Route path="/background" element={<ProtectedRoute element={<Background />} />} />
                        <Route path="/profile/:username" element={<ProfileDetail />} /> {/* Public Route */}
                    </Routes>
                </Box>
            </Router>
        </AuthProvider>
    );
};

export default App;
