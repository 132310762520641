import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, Avatar, CssBaseline, CardContent, IconButton } from '@mui/material';
import { MailOutline, WhatsApp, Facebook, Instagram, LinkedIn, YouTube, PhoneCallback, MapRounded } from '@mui/icons-material';
import { SiTiktok } from 'react-icons/si';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../profile.css';

const ProfileDetail = () => {
    const { username } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://visoncard.yoshidindonesia.com/user/user/${username}`);
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError("Failed to fetch user data");
                toast.error("Failed to fetch user data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [username]);

    if (loading) {
        return <Typography variant="body1">Loading...</Typography>;
    }

    if (error) {
        return <Typography variant="body1">{error}</Typography>;
    }

    return (
        <Container 
            component="main" 
            maxWidth="sm" 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                minHeight: '50vh', 
                height: '180%', 
                width: '100%', 
                overflow: 'hidden' 
            }}
        >
            <CssBaseline />
           
            <Box
                className="profile-background"
                sx={{
                    backgroundColor: 'transparent',
                    position: 'absolute',
                    top: 0, // Ensure it starts from the top
                    left: 0, // Ensure it starts from the left
                    width: '100vw', // Full viewport width
                    height: '100vh', // Full viewport height
                    backgroundImage: `url(${user.backgroundImage})`,
                    backgroundSize: 'cover', // Ensure the image covers the box
                    backgroundPosition: 'center', // Center the background image
                    backgroundRepeat: 'no-repeat', // Avoid image repetition
                    zIndex: -1 // Ensure it is behind the content
                }}
            />

            <CardContent 
                className="profile-card-content" 
                sx={{ 
                    marginTop: '85px',
                    position: 'relative', 
                    textAlign: 'center', 
                    width: '100%', 
                    height: '100%' 
                }}
            >
                {user ? (
                    <>
                        <Avatar src={user.image} alt={user.namalengkap} sx={{ width: 150, height: 150, mb: 2, mx: 'auto', borderRadius: 2 }} />
                        <Typography 
                            variant="h4" 
                            component="h1" 
                            sx={{ 
                                fontWeight: 'bold', 
                                mb: 1, 
                                color: user.fontcolor || 'inherit' // Apply dynamic color
                            }}
                        >
                            {user.namalengkap}
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                mb: 1, 
                                fontWeight: 'bold', 
                                color: user.fontcolor || 'inherit' // Apply dynamic color
                            }}
                        >
                            {user.jabatan}
                        </Typography>
                        <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                            <IconButton
                                color="primary"
                                href={`mailto:${user.email}`}
                                target="_blank"
                                sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#EA4335' } }}
                            >
                                <MailOutline />
                            </IconButton>
                            <IconButton
                                color="primary"
                                href={`https://wa.me/${user.whatsapp}`}
                                target="_blank"
                                sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#25D366' } }}
                            >
                                <WhatsApp />
                            </IconButton>
                            {user.facebook && (
                                <IconButton
                                    color="primary"
                                    sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#1877F2' } }}
                                    href={user.facebook}
                                    target="_blank"
                                >
                                    <Facebook />
                                </IconButton>
                            )}
                            {user.instagram && (
                                <IconButton
                                    color="primary"
                                    sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#E4405F' } }}
                                    href={user.instagram}
                                    target="_blank"
                                >
                                    <Instagram />
                                </IconButton>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                            {user.linkedin && (
                                <IconButton
                                    color="primary"
                                    sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#0077B5' } }}
                                    href={user.linkedin}
                                    target="_blank"
                                >
                                    <LinkedIn />
                                </IconButton>
                            )}
                            {user.tiktok && (
                                <IconButton
                                    color="primary"
                                    sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#69C9D0' } }}
                                    href={user.tiktok}
                                    target="_blank"
                                >
                                    <SiTiktok />
                                </IconButton>
                            )}
                            {user.youtube && (
                                <IconButton
                                    color="primary"
                                    sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#69C9D0' } }}
                                    href={user.youtube}
                                    target="_blank"
                                >
                                    <YouTube />
                                </IconButton>
                            )}
                            <IconButton
                                color="primary"
                                href={`tel:${user.whatsapp}`}
                                sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#25D366' } }}
                            >
                                <PhoneCallback />
                            </IconButton>
                        </Box>

                        <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                            {user.alamat ? (
                                <IconButton
                                    color="primary"
                                    sx={{ backgroundColor: '#000000', color: '#fff', '&:hover': { backgroundColor: '#69C9D0' } }}
                                    href={user.alamat} // Use the direct URL from user.alamat
                                    target="_blank"
                                >
                                    <MapRounded />
                                </IconButton>
                            ) : (
                                toast.info("Address link is not available")
                            )}
                        </Box>
                        <Typography 
                            variant="body1"
                            sx={{ color: user.fontcolor || 'inherit' }} // Apply dynamic color
                        >
                            Address
                        </Typography>
                    </>
                ) : (
                    <Typography variant="body1">User not found</Typography>
                )}

                

                <Typography sx={{backgroundColor: 'grey',marginTop: '150px', color: 'white'}}>support@v1sioncard.com</Typography>
            </CardContent>

            <ToastContainer />
        </Container>
    );
};

export default ProfileDetail;
