import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, CssBaseline, Typography, Grid, Card, CardContent } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Sidebar from './pages/Sidebar'; 
import Navbar from '../components/Navbar'; 
import { useAuth } from '../contexts/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../components/dashboard.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff8c00',
        },
        background: {
            default: '#2e2e2e',
        },
    },
    typography: {
        h5: {
            fontWeight: 300,
            color: '#ffffff',
        },
    },
});

const Dashboard = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    
    const [userCount, setUserCount] = useState(0);
    const [backgroundCount, setBackgroundCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const userResponse = await axios.get('https://visoncard.yoshidindonesia.com/user');
                const contentResponse = await axios.get('https://visoncard.yoshidindonesia.com/background');
                setUserCount(userResponse.data.length);
                setBackgroundCount(contentResponse.data.length);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchCounts();
    }, []);

    const handleLogout = () => {
        logout();
        toast.success("Logged out successfully!");
        navigate('/login');
    };

    const [open, setOpen] = useState(true);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleBackgroundClick = () => {
        navigate('/background');
    };

    const handleUserClick = () => {
        navigate('/users');
    };

    if (loading) return <Typography variant="h6" color="#ffffff">Loading...</Typography>;
    if (error) return <Typography variant="h6" color="error">{error}</Typography>;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar onToggle={handleDrawerToggle} />
            <Sidebar open={open} onToggle={handleDrawerToggle} />
            <Container style={{ marginTop: '100px', marginLeft: '320px' }}>
                <Typography variant="h4" gutterBottom color="#ffffff">
                    Dashboard
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className="flip-card">
                            <div className="flip-card-inner">
                                <Card className="flip-card-front" sx={{ color: '#ffffff', padding: 2, backgroundSize: 'cover',backgroundImage: 'url(/bg-card.jpg)', borderRadius:'20px' }}>
                                    <CardContent>
                                        <Typography style={{color: 'black'}} variant="h4">Background</Typography>
                                        <Typography style={{fontSize:'80px', color:'black', justifyContent:'center', textAlign:'center'}}>{backgroundCount}</Typography>
                                        <Button style={{fontWeight:'bold'}} onClick={handleBackgroundClick} variant="contained" color="primary" sx={{ mt: 2 }}>
                                            View Details
                                        </Button>
                                    </CardContent>
                                </Card>
                                <Card className="flip-card-back" sx={{ color: '#ffffff', padding: 2, backgroundSize: 'cover', backgroundImage: 'url(/bg-flip.png)', borderRadius:'20px' }}>
                                    <CardContent>
                                        <Typography style={{color: 'black'}} variant="h4">Background</Typography>
                                        <Typography style={{fontSize:'80px', color:'black', justifyContent:'center', textAlign:'center'}}>{backgroundCount}</Typography>
                                        <Button style={{fontWeight:'bold'}} onClick={handleBackgroundClick} variant="contained" color="primary" sx={{ mt: 2 }}>
                                            View Details
                                        </Button>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className="flip-card">
                            <div className="flip-card-inner">
                                <Card className="flip-card-front" sx={{ color: '#ffffff', padding: 2, backgroundSize: 'cover', backgroundImage: 'url(/bg-card.jpg)', borderRadius:'20px' }}>
                                    <CardContent>
                                        <Typography style={{color: 'black'}} variant="h4">Users</Typography>
                                        <Typography style={{fontSize:'80px', color:'black', justifyContent:'center', textAlign:'center'}}>{userCount}</Typography>
                                        <Button style={{fontWeight:'bold'}} onClick={handleUserClick} variant="contained" color="primary" sx={{ mt: 2 }}>
                                            View Details
                                        </Button>
                                    </CardContent>
                                </Card>
                                <Card className="flip-card-back" sx={{ color: '#ffffff', padding: 2, backgroundSize: 'cover', backgroundImage: 'url(/bg-flip.png)', borderRadius:'20px' }}>
                                    <CardContent>
                                        <Typography style={{color: 'black'}} variant="h4">Users</Typography>
                                        <Typography style={{fontSize:'80px', color:'black', justifyContent:'center', textAlign:'center'}}>{userCount}</Typography>
                                        <Button style={{fontWeight:'bold'}} onClick={handleUserClick} variant="contained" color="primary" sx={{ mt: 2 }}>
                                            View Details
                                        </Button>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <ToastContainer />
        </ThemeProvider>
    );
};

export default Dashboard;
